import { Button } from "../../component/Button/Button";
import { NavigationButton } from "../../component/Button/NavigationButton";
import { deleteDeck, getDeckStorage } from "../../service/deckStorageService";

export function SavedDeck() {
  const deckStorage = getDeckStorage();

  return (
    <div className="container mx-auto py-4 flex flex-col gap-4">
      <div>
        <NavigationButton href="/" className="grow-0">
          Home
        </NavigationButton>
      </div>
      <div>
        {Object.keys(deckStorage).map((id) => {
          const deck = deckStorage[id];
          return (
            <div className="w-full flex gap-2 items-center">
              <div className="w-2/3">{deck.name}</div>
              <NavigationButton href={`/${deck.id}`}>Open</NavigationButton>
              <Button onClick={() => deleteDeck(deck.id)}>Delete</Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
