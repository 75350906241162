const STORAGE_KEY = "deckStorage";

interface DeckStorageData {
  id: string;
  name: string;
  createdAt: number;
}

const deckStorage: Record<string, DeckStorageData> = JSON.parse(
  localStorage.getItem(STORAGE_KEY) ?? "{}"
);

function createDeckStorageData(id: string, name: string): DeckStorageData {
  return {
    id,
    name,
    createdAt: Date.now(),
  };
}

export function getDeckStorage() {
  return deckStorage;
}

export function storeDeck(id: string, name?: string) {
  name = name ? name : `Unnamed Deck - ${id.split("-")[0]}`;
  const data = createDeckStorageData(id, name);
  deckStorage[id] = data;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(deckStorage));
}

export function deleteDeck(id: string) {
  delete deckStorage[id];
  localStorage.setItem(STORAGE_KEY, JSON.stringify(deckStorage));
}
