import { useEffect, useRef, useState } from "react";
import { Card } from "../../model/card";
import { CARD_CATEGORY, CARD_LIMIT } from "../../constant";
import { useImmer } from "use-immer";
import { CardInDeck } from "./deckBuilder.enity";
import { getCard, getCardList } from "../../service/cardService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as client from "../../client/server";
import { storeDeck } from "../../service/deckStorageService";

export interface DeckBuilderFilter {
  sid?: string;
  category?: Array<CARD_CATEGORY>;
  cost?: [number, number];
  power?: [number, number];
  counter?: number;
  color?: Array<string>;
  rarity?: Array<string>;
  feature?: Array<string>;
}

export const useDeckBuilder = () => {
  const init = useRef<boolean>(false);
  const [cardList, setCardList] = useState<Array<Card>>();
  const [leader, setLeader] = useState<Card>();
  const [deck, setDeck] = useImmer<Record<string, CardInDeck>>({});
  const [filter, setFilter] = useImmer<DeckBuilderFilter>({});
  const { id: deckId } = useParams();
  const navigate = useNavigate();

  const addCardToDeck = (card: Card) => {
    setDeck((draft) => {
      if (!draft[card.uid]) {
        draft[card.uid] = { ...card, count: 0 };
      }

      draft[card.uid].count = Math.min(CARD_LIMIT, draft[card.uid].count + 1);
    });
  };
  const removeCardFromDeck = (card: Card) => {
    setDeck((draft) => {
      if (!draft[card.uid]) {
        return;
      }

      draft[card.uid].count = Math.max(0, draft[card.uid].count - 1);
      if (draft[card.uid].count === 0) {
        delete draft[card.uid];
      }
    });
  };

  const addCard = (card: Card) => {
    if (card.category === CARD_CATEGORY.LEADER) {
      setLeader(card);
      return;
    }

    addCardToDeck(card);
  };
  const removeCard = (card: Card) => {
    if (card.category === CARD_CATEGORY.LEADER) {
      setLeader(undefined);
      return;
    }

    removeCardFromDeck(card);
  };

  const saveDeck = (name?: string) => {
    const result: Record<string, number> = {};
    for (const id in deck) {
      result[id] = deck[id].count;
    }
    if (leader) {
      result[leader.uid] = 1;
    }

    client.saveDeck(result).then((id) => {
      storeDeck(id, name);
      navigate(`/${id}`);
    });
  };

  const loadCardList = (cardCount: Record<string, number>) => {
    const cardKey = Object.keys(cardCount);
    getCard(cardKey).then((data) => {
      for (const card of data) {
        for (let index = 0; index < cardCount[card.uid]; index++) {
          addCard(card);
        }
      }
    });
  };

  const loadDeck = () => {
    if (deckId) {
      client
        .getDeck(deckId)
        .then(loadCardList)
        .catch(() => {
          navigate("/");
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getCardList(filter).then(setCardList);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [filter]);

  useEffect(() => {
    if (init.current) {
      return;
    }

    init.current = true;
    loadDeck();
  }, []);

  return {
    cardList,
    leader,
    deck,
    addCard,
    removeCard,
    setFilter,
    saveDeck,
  };
};
