import classNames from "classnames";
import { Card } from "../model/card";
import { Cardback } from "../assets/cardback";
import { LazyLoadImage } from "react-lazy-load-image-component";

const imgEndpoint = "https://asia-tw.onepiece-cardgame.com/cardlist/";

interface ICardBlockProp {
  className?: string;
  card?: Card;
  onClickCard?: (card: Card) => void;
}

export const CardBlock: React.FC<ICardBlockProp> = ({
  className,
  card,
  onClickCard,
}) => {
  return (
    <div
      className={classNames("p-[2px] select-none aspect-[600/838]", className, {
        "rounded cursor-pointer hover:outline hover:outline-1": !!onClickCard,
      })}
    >
      {!card && (
        <div>
          <Cardback className="w-full" />
        </div>
      )}

      {card && (
        <div onClick={() => onClickCard && onClickCard(card)}>
          <LazyLoadImage
            title={card.cardName}
            alt={card.cardName}
            src={imgEndpoint + card.cardImg}
          />
        </div>
      )}
    </div>
  );
};
